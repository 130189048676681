import React, { useState, useEffect } from 'react';
import Select from 'phoenix-components/lib/Select';
import PropTypes from 'prop-types';
import cx from 'classnames'
import { CountriesAndUnicodes } from 'ui/countries';
import styles from './Phone.module.css'

const countries = CountriesAndUnicodes.map(x=>({
  label:`${x.label} (${x.dial})`,
  value:x.dial
}))

function Phone({
  selectLabel,
  onChange,
  value,
  isCountryDisable,
  placeholder,
  required
}) {
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const selectedCountry = countries.find(x=>x.value === (country || ''));

  const onCountryChange = e => {
    setCountry(e.value);
    onChange({
      phone, countryCode: e.value,
    })
  }

  useEffect(() => {
    if (value?.phone) {
      setPhone(value.phone);
    }
    if (value?.countryCode) {
      setCountry(value.countryCode);
    }
  }, [value?.countryCode, value?.phone])

  const onPhoneChange = e => {
    setPhone(e.target.value);
    onChange({
      phone: e.target.value, countryCode: country,
    })
  }


  return (
    <div className={styles.main}>
      <div className={styles.select}>
        <div className={styles.selectLabel}>
          {selectLabel}
        </div>
        <div>
        </div>
        <Select
          value={selectedCountry}
          onChange={onCountryChange}
          options={countries}
          isDisabled={isCountryDisable}
          className={styles.customSelect}
        />
      </div>
      <div className={styles.inputMain}>
        <input
          required={required}
          type='number'
          placeholder={placeholder}
          className={cx(styles['text-form-input'], styles.textArea)}
          onChange={onPhoneChange}
          value={phone}
        />
      </div>

    </div>
  );
}

Phone.defaultProps = {
  selectLabel: 'Country Code',
  isCountryDisable: false,
  placeholder: 'Enter Phone Number'
};

Phone.propTypes = {
  selectLabel: PropTypes.string,
  isCountryDisable: PropTypes.bool,
  placeholder: PropTypes.string
};

export default Phone
